
// @ts-nocheck


export const localeCodes =  [
  "en",
  "vi",
  "cn"
]

export const localeLoaders = {
  "en": [{ key: "../lang/en.yml", load: () => import("../lang/en.yml" /* webpackChunkName: "locale_D_58_D_58_dev_web_lang_en_yml" */), cache: true }],
  "vi": [{ key: "../lang/vi.yml", load: () => import("../lang/vi.yml" /* webpackChunkName: "locale_D_58_D_58_dev_web_lang_vi_yml" */), cache: true }],
  "cn": [{ key: "../lang/cn.yml", load: () => import("../lang/cn.yml" /* webpackChunkName: "locale_D_58_D_58_dev_web_lang_cn_yml" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n.config.ts?hash=bffaebcb&config=1" /* webpackChunkName: "__i18n_config_ts_bffaebcb" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./i18n.config.ts",
  "locales": [
    {
      "name": "English",
      "code": "en",
      "dir": "ltr",
      "language": "en",
      "files": [
        "D:/dev_web/lang/en.yml"
      ]
    },
    {
      "name": "Tiếng Việt",
      "code": "vi",
      "dir": "ltr",
      "language": "vi",
      "files": [
        "D:/dev_web/lang/vi.yml"
      ]
    },
    {
      "name": "中国人",
      "code": "cn",
      "dir": "ltr",
      "language": "cn",
      "files": [
        "D:/dev_web/lang/cn.yml"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_and_default",
  "lazy": true,
  "langDir": "lang",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "name": "English",
    "code": "en",
    "dir": "ltr",
    "language": "en",
    "files": [
      {
        "path": "D:/dev_web/lang/en.yml"
      }
    ]
  },
  {
    "name": "Tiếng Việt",
    "code": "vi",
    "dir": "ltr",
    "language": "vi",
    "files": [
      {
        "path": "D:/dev_web/lang/vi.yml"
      }
    ]
  },
  {
    "name": "中国人",
    "code": "cn",
    "dir": "ltr",
    "language": "cn",
    "files": [
      {
        "path": "D:/dev_web/lang/cn.yml"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
